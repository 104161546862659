import React from 'react'
import posed, { PoseGroup } from 'react-pose'

const transitionDuration = 300
const transitionDelay = 350

const Transition = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: transitionDuration },
    delay: transitionDelay,
    beforeChildren: true,
  },
  exit: { opacity: 0, transition: { duration: transitionDuration } },
})

export default ({ children, ...props }) => (
  <>
    <PoseGroup>
      <Transition
        key={props.location.pathname}
      >
        {children}
      </Transition>
    </PoseGroup>
  </>
)
